import LearningJourneysHeading from './LearningJourneysHeading';
import LearningJourneysMain from './LearningJourneysMain/LearningJourneysMain';

const LearningJourneysListPage = () => {
  return (
    <div className="flex w-full flex-col">
      <LearningJourneysHeading />
      <LearningJourneysMain />
    </div>
  );
};

export default LearningJourneysListPage;
