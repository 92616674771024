import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import Dialog from '../../../../components/Dialog/Dialog';
import Select from '../../../../components/Select';
import { BasicSpinner } from '../../../../components/Spinners';
import { BADGE_ACTION, ModalType } from '../../constants/badges';
import useUSHGHQBadgeDetailsQuery from '../../hooks/useUSHGHQBadgeDetailsQuery';
import BadgeForm from './BadgeModal/BadgeForm';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../../constants/user';
import useStrapiBadgesData from '../../hooks/useStrapiBadgeData';
import { ERROR_CODE } from '../../constants/errors';
import { useStrapiSharedData } from '../../../shared/hooks/useStrapiSharedData';

interface Props {
  modalTitle: string;
  isManageModalOpen?: boolean;
  isEditModalOpen?: boolean;
  setIsBadgeModalOpen: (open: boolean) => void;
  formActionButtonLabel?: string;
  buttonIcon?: React.ReactNode;
  modalType: ModalType;
  refetch?: any;
  badgeId: number;
  mappedOptions?: { display: string; value: string }[];
  handleLanguageChange?: (newLanguage: string) => void;
  showLanguageDropdown?: boolean;
}

const EditAndManageBadgeModal = ({
  modalTitle,
  isManageModalOpen,
  isEditModalOpen,
  setIsBadgeModalOpen,
  formActionButtonLabel,
  buttonIcon,
  modalType,
  refetch,
  badgeId,
  showLanguageDropdown = false,
}: Props) => {
  // state

  const [buttonLabel, setButtonLabel] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // create, update, or delete of badge action in progress
  const [actionInProgress, setActionInProgress] = useState<keyof typeof BADGE_ACTION | null>(null);

  // state

  // strapi
  const { modal } = useStrapiBadgesData();
  const { addBadgeButton, editBadgeButton } = modal;
  const {
    language: { StrapiLanguageOptions },
  } = useStrapiSharedData();

  // strapi

  // Map `label` to `display` for Select component with null check
  const mappedOptions = useMemo(
    () =>
      StrapiLanguageOptions?.map((option: { label: string; value: string }) => ({
        display: option.label,
        value: option.value,
      })) || [],
    [StrapiLanguageOptions]
  );

  // Find the next language to default lang
  const nextLanguage = useMemo(
    () =>
      mappedOptions.find(
        (option: { value: string }) => option.value !== DEFAULT_PREFERRED_LANGUAGE
      ),
    [mappedOptions]
  );

  const [selectedLanguage, setSelectedLanguage] = useState(nextLanguage ? nextLanguage.value : '');

  const handleLanguageChange = (newLanguage: string) => {
    setSelectedLanguage(newLanguage);
  };

  // query
  const badgeDetailsQuery = useUSHGHQBadgeDetailsQuery({
    badgeId,
    language: selectedLanguage,
    isEnabled: !!isManageModalOpen,
  });

  const defaultLangBadgeDetailsQuery = useUSHGHQBadgeDetailsQuery({
    badgeId,
    language: DEFAULT_PREFERRED_LANGUAGE,
    isEnabled:
      !!isEditModalOpen ||
      (!badgeDetailsQuery.data && badgeDetailsQuery.errorType === ERROR_CODE.BADGE_ID_NOT_FOUND),
  });
  // query

  useEffect(() => {
    if (badgeDetailsQuery.data || badgeDetailsQuery.errorType === ERROR_CODE.BADGE_ID_NOT_FOUND) {
      if (
        !badgeDetailsQuery.data &&
        badgeDetailsQuery.errorType === ERROR_CODE.BADGE_ID_NOT_FOUND
      ) {
        setButtonLabel(addBadgeButton);
      } else {
        setButtonLabel(editBadgeButton);
      }
    }
  }, [badgeDetailsQuery.data, badgeDetailsQuery.errorType, addBadgeButton, editBadgeButton]);

  const prePopulatedData = useMemo(() => {
    if (modalType === 'update' && defaultLangBadgeDetailsQuery.data) {
      // Prioritize default language data when in edit mode
      return defaultLangBadgeDetailsQuery.data;
    }

    // Otherwise, use the manage mode data or fallback to defaultLangBadgeDetailsQuery.data
    return badgeDetailsQuery.data || defaultLangBadgeDetailsQuery.data;
  }, [modalType, badgeDetailsQuery.data, defaultLangBadgeDetailsQuery.data]);

  useEffect(() => {
    setIsLoading(
      badgeDetailsQuery.isInitialLoading || defaultLangBadgeDetailsQuery.isInitialLoading
    );
  }, [badgeDetailsQuery.isInitialLoading, defaultLangBadgeDetailsQuery.isInitialLoading]);

  return (
    <div className="mx-3 flex h-[80vh] flex-col gap-6 rounded-lg border border-zinc-900 bg-card-bg p-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6 sm:h-fit ">
      <div className="flex items-center">
        <Dialog.Title className="text-lg font-semibold leading-[24px] text-white">
          {modalTitle}
        </Dialog.Title>
        {showLanguageDropdown && prePopulatedData && (
          <Select
            aria-label="Language options"
            options={mappedOptions?.length ? mappedOptions : []}
            value={selectedLanguage}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => handleLanguageChange?.(e.target.value)}
            disabledOptions={modalType === 'manage' ? [DEFAULT_PREFERRED_LANGUAGE] : []}
            className="ml-auto"
            disabled={!!actionInProgress}
          />
        )}
      </div>
      {isLoading ? (
        <div className="flex w-full justify-center py-4">
          <BasicSpinner className="h-[30vh] text-white" />
        </div>
      ) : (
        (prePopulatedData || modalType === 'create') && (
          <BadgeForm
            setIsBadgeModalOpen={setIsBadgeModalOpen}
            formActionButtonLabel={modalType === 'manage' ? buttonLabel : formActionButtonLabel}
            badgePreFetchData={prePopulatedData}
            buttonIcon={buttonIcon}
            refetch={refetch}
            modalType={modalType}
            language={modalType === 'manage' ? selectedLanguage : DEFAULT_PREFERRED_LANGUAGE}
            errorType={badgeDetailsQuery.errorType}
            setErrorType={badgeDetailsQuery.setErrorType}
            setActionInProgress={setActionInProgress}
          />
        )
      )}
    </div>
  );
};

export default EditAndManageBadgeModal;
