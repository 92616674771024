import { USHG_HQ_APP_ROLE } from '../../../constants';
import HasAccess from '../../shared/components/HasAccess';
import EnterpriseAdminSettingsPage from './EnterpriseAdminSettingsPage';
import UsersSettingsPage from './UserSettingsPage';
import EnterpriseManagerSettingsPage from './EnterpriseManagerSettingsPage';
import EnterpriseLeaderSettingsPage from './EnterpriseLeaderSettingsPage';
import USHGAdminSettingsPage from './USHGAdminSettingsPage';
const SettingsPage = () => {
  return (
    <div>
      {/* Enterprise Admin Settings  */}
      <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN]} Fallback={null}>
        <EnterpriseAdminSettingsPage />
      </HasAccess>
      {/* Enterprise Leader Settings  */}
      <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_LEADER]} Fallback={null}>
        <EnterpriseLeaderSettingsPage />
      </HasAccess>
      {/* Enterprise Manager Settings  */}
      <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER]} Fallback={null}>
        <EnterpriseManagerSettingsPage />
      </HasAccess>
      {/* USHG Admin Settings  */}
      <HasAccess roles={[USHG_HQ_APP_ROLE.USHG_ADMIN]} Fallback={null}>
        <USHGAdminSettingsPage />
      </HasAccess>
      {/* User Settings  */}
      <HasAccess
        roles={[USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER, USHG_HQ_APP_ROLE.INDIVIDUAL_USER]}
        Fallback={null}
      >
        <UsersSettingsPage />
      </HasAccess>
    </div>
  );
};

export default SettingsPage;
