import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { BasicSpinner } from '../../../../components/Spinners';
import { Toast } from '../../../../components/Toast';
import { ERROR_TYPES } from '../../../../constants/error-types.constants';
import { useStrapiDataHelper } from '../../../../hooks/useStrapiData';
import {
  useCourseBasicDetailsQuery,
  useGetModulesByIdQuery,
  usePublishCourseMutation,
} from '../../hooks';
import { useStrapiHQAdminCoursesData } from '../../hooks/useStrapiHQAdminCourseData';
import { PublishCourseType } from '../../types';
import { CoursesEmptyState, CoursesErrorState } from '../common';
import CoursesMain from './components/CoursesMain';
import PageHeader from './components/PageHeader';
import { SideBarLayoutPageContentWrapper } from '../../../../components/Wrapper';
const CoursesPage = () => {
  // get courseId from params
  const id = parseInt(useParams().id as string);
  const navigate = useNavigate();

  const [isPublishLoading, setIsPublishLoading] = useState<boolean>(false);
  const publishCourseMutation = usePublishCourseMutation();

  // pull data from strapi helper
  const strapidata = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  const {
    toastMessages: {
      courseCreationFailedDueToNoMedia,
      courseCreationFailedDueToNoModule,
      courseCreationFailedDueToInvalidId,
      courseMadeLive,
      publishCourseError,
    },
    loadingMessages,
    emptyMessages,
    topNav,
  } = useStrapiHQAdminCoursesData();

  // query to fetch the course basic details
  const courseBasicDetailsQuery = useCourseBasicDetailsQuery({ id });
  // query to fetch modules and assessments
  const courseModulesQuery = useGetModulesByIdQuery({ id });

  const displayLoader =
    (courseBasicDetailsQuery.isLoading && !courseBasicDetailsQuery.data) ||
    (courseModulesQuery.isLoading && !courseModulesQuery.data);

  // Empty State
  const isEmptyState =
    courseBasicDetailsQuery.data &&
    Object.keys(courseBasicDetailsQuery).length === 0 &&
    courseModulesQuery.data &&
    courseModulesQuery.data.length === 0;

  // Course doesnot exist error
  const notExistError = courseBasicDetailsQuery.errorType && !courseBasicDetailsQuery.data;

  // Error State
  const errorState =
    courseBasicDetailsQuery.isError &&
    !courseBasicDetailsQuery.data &&
    courseModulesQuery.isError &&
    !courseModulesQuery.data;

  const onClick = () => {
    setIsPublishLoading(true);
    let toastMessage = '';
    let toastError = false;
    if (!id) {
      toastError = true;
      toastMessage = courseCreationFailedDueToInvalidId;
    }
    // Check if thumbnail and preview vdeo is present for course before publishing
    if (!toastError && !courseBasicDetailsQuery.data?.thumbnailUrl) {
      toastError = true;
      toastMessage = courseCreationFailedDueToNoMedia;
    }
    // Check if course has modules before publishing course
    if (!toastError && courseModulesQuery.data.length == 0) {
      toastError = true;
      toastMessage = courseCreationFailedDueToNoModule;
    }
    // Toast error message
    if (toastError) {
      setIsPublishLoading(false);
      toast.custom((t) => {
        return (
          <Toast
            variant="error"
            Title={publishCourseError}
            SubTitle={toastMessage}
            toastInstance={t}
          />
        );
      });
    } else {
      // Reuest Bosy
      const data: PublishCourseType = {
        id: id,
        isActive: !courseBasicDetailsQuery.data?.isActive,
      };
      // API Call
      publishCourseMutation.mutate(data, {
        onSuccess: (response) => {
          const { message: responseMessage } = response.data;
          setIsPublishLoading(false);
          courseBasicDetailsQuery.refetch();
          toast.custom((t) => {
            return (
              <Toast
                variant="success"
                Title={courseMadeLive}
                SubTitle={responseMessage}
                toastInstance={t}
              />
            );
          });
        },
        onError: () => {
          setIsPublishLoading(false);
        },
      });
    }
  };

  return (
    <div className="flex h-full w-full flex-col">
      {/* Course Details loading */}
      {displayLoader && (
        <div className="flex h-full w-full items-center justify-center bg-black py-4">
          <BasicSpinner className="h-6 w-6 text-white" />
          <span className="text-lg font-normal leading-[24px] text-zinc-50">
            {loadingMessages.courseLoading}...
          </span>
        </div>
      )}
      {/* Course Details loaded */}
      {id && courseBasicDetailsQuery.data && courseModulesQuery.data && !displayLoader && (
        <main id="main-content" className="flex flex-col gap-5">
          <PageHeader
            courseName={courseBasicDetailsQuery.data.name}
            onclickHandler={onClick}
            isActive={courseBasicDetailsQuery.data.isActive}
            isButtonLoading={isPublishLoading}
          />
          <SideBarLayoutPageContentWrapper>
            <CoursesMain
              courseBasicDetailsQuery={courseBasicDetailsQuery}
              courseModulesQuery={courseModulesQuery}
              courseId={id}
            />
          </SideBarLayoutPageContentWrapper>
        </main>
      )}

      {/* Empty State */}
      {isEmptyState && !displayLoader && (
        <div className="flex w-full">
          <CoursesEmptyState message={<span>{emptyMessages.emptyCourse}</span>} />
        </div>
      )}

      {/* Course doesnot exist error state */}
      {notExistError && (
        <div className="flex w-full">
          <CoursesEmptyState
            message={
              <div className="flex flex-col items-center gap-y-8">
                <span>{emptyMessages.commonEmptyMessage}</span>
                <button
                  className="w-min whitespace-nowrap rounded-lg border border-zinc-800 bg-zinc-900 py-2 px-4"
                  onClick={() => navigate('/manage/courses')}
                >
                  {topNav.backToCourseButton}
                </button>
              </div>
            }
          />
        </div>
      )}
      {/* Error State */}
      {errorState && (
        <div className="flex h-full items-center justify-center">
          <CoursesErrorState
            refetchArray={[courseBasicDetailsQuery.refetch, courseModulesQuery.refetch]}
            isRefetching={courseBasicDetailsQuery.isRefetching || courseModulesQuery.isRefetching}
            allowsRefetch={true}
            type={ERROR_TYPES.SOMETHING_WENT_WRONG}
            message={strapiErrorMessage['SYSTEM_ERROR']}
          />
        </div>
      )}
    </div>
  );
};

export default CoursesPage;
