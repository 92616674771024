import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../../../config/react-query';
import { createBadge, createBadgeInMultiLang, updateBadge } from '../api/badges';
import {
  CreateBadgeInMultiLangPayload,
  CreateBadgePayload,
  UpdateBadgePayload,
} from '../constants/badges';

export const useCreateBadgeMutation = () => {
  return useMutation({
    mutationFn: async (payload: CreateBadgePayload) => {
      return await createBadge(payload);
    },
  });
};

export const useCreateBadgeInMultiLangMutation = () => {
  return useMutation({
    mutationFn: async (payload: CreateBadgeInMultiLangPayload) => {
      return await createBadgeInMultiLang(payload);
    },
  });
};

export const useUpdateBadgeMutation = () => {
  return useMutation({
    mutationFn: async (payload: UpdateBadgePayload) => {
      return await updateBadge(payload);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [
          {
            scope: 'badge',
            item: 'ushghq-admin-badge-details',
          },
        ],
      });
    },
  });
};
