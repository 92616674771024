import { useNavigate, useParams } from 'react-router-dom';
import { BasicSpinner } from '../../../../components/Spinners';
import { ERROR_TYPES } from '../../../../constants/error-types.constants';
import { useStrapiDataHelper } from '../../../../hooks/useStrapiData';
import { useAssessmentQuestionsQuery, useAssessmentBasicDetailsQuery } from '../../hooks';
import { useStrapiHQAdminCoursesData } from '../../hooks/useStrapiHQAdminCourseData';
import { CoursesEmptyState, CoursesErrorState } from '../common';
import { AssessmentMain } from './components/AssessmentMain';
import PageHeader from './components/PageHeader';
import { SideBarLayoutPageContentWrapper } from '../../../../components/Wrapper';

const CourseAssessmentPage = () => {
  const navigate = useNavigate();
  const pathParams = useParams();

  // get assessmentId from params
  const assessmentId = parseInt(pathParams.assessmentId as string);

  // query to fetch assessment basic details
  const assessmentBasicDetailsQuery = useAssessmentBasicDetailsQuery({ assessmentId });

  // query to fetch assessment questions
  const asssessmentQuestionsQuery = useAssessmentQuestionsQuery({ assessmentId });

  // pull data from strapi helper
  const strapidata = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;

  const displayLoader =
    (assessmentBasicDetailsQuery.isLoading && !assessmentBasicDetailsQuery.data) ||
    (asssessmentQuestionsQuery.isLoading && !asssessmentQuestionsQuery.data);

  // Empty State

  const isEmptyState =
    assessmentBasicDetailsQuery.data &&
    Object.keys(assessmentBasicDetailsQuery).length === 0 &&
    asssessmentQuestionsQuery.data &&
    asssessmentQuestionsQuery.data.length === 0;

  // Assessment doesnot exist error
  const notExistError = assessmentBasicDetailsQuery.errorType && !assessmentBasicDetailsQuery.data;

  // Error State
  const errorState =
    assessmentBasicDetailsQuery.isError &&
    !assessmentBasicDetailsQuery.data &&
    asssessmentQuestionsQuery.isError &&
    !asssessmentQuestionsQuery.data;
  const { loadingMessages, topNav, emptyMessages } = useStrapiHQAdminCoursesData();
  return (
    <div className="flex h-full flex-col">
      {/* Assessment Details loading */}
      {displayLoader && (
        <div className="flex h-full w-full items-center justify-center bg-black py-4">
          <BasicSpinner className="h-6 w-6 text-white" />
          <span className="text-lg font-normal leading-[24px] text-zinc-50">
            {loadingMessages.assessmentLoading}...
          </span>
        </div>
      )}
      {/* Assessment Details loaded */}
      {assessmentId &&
        assessmentBasicDetailsQuery.data &&
        asssessmentQuestionsQuery.data &&
        !displayLoader && (
          <main id="main-content" className="flex flex-col gap-5">
            <PageHeader name={assessmentBasicDetailsQuery.data.name} />
            <SideBarLayoutPageContentWrapper>
              <AssessmentMain
                assessmentBasicDetailsQuery={assessmentBasicDetailsQuery}
                asssessmentQuestionsQuery={asssessmentQuestionsQuery}
                assessmentId={assessmentId}
              />
            </SideBarLayoutPageContentWrapper>
          </main>
        )}
      {/* Empty State */}
      {isEmptyState && !displayLoader && (
        <div className="flex w-full">
          <CoursesEmptyState message={<span>{emptyMessages.emptyAssessment}</span>} />
        </div>
      )}
      {/* Course doesnot exist error state */}
      {notExistError && (
        <div className="flex w-full">
          <CoursesEmptyState
            message={
              <div className="flex flex-col items-center gap-y-8">
                <span>{emptyMessages.commonEmptyMessage}</span>
                <button
                  className="w-min whitespace-nowrap rounded-lg border border-zinc-800 bg-zinc-900 py-2 px-4"
                  onClick={() => navigate('/manage/courses')}
                >
                  {topNav.backToCourseButton}
                </button>
              </div>
            }
          />
        </div>
      )}
      {/* Error State */}
      {errorState && !notExistError && (
        <div className="flex h-full items-center justify-center">
          <CoursesErrorState
            refetchArray={[assessmentBasicDetailsQuery.refetch, asssessmentQuestionsQuery.refetch]}
            isRefetching={
              assessmentBasicDetailsQuery.isRefetching || asssessmentQuestionsQuery.isRefetching
            }
            allowsRefetch={true}
            type={ERROR_TYPES.SOMETHING_WENT_WRONG}
            message={strapiErrorMessage['SYSTEM_ERROR']}
          />
        </div>
      )}
    </div>
  );
};

export default CourseAssessmentPage;
