import { USHG_HQ_APP_ROLE } from '../../../constants';
import HasAccess from '../../shared/components/HasAccess';
// import { Courses } from '../components';
import { Courses, CoursesTableMain } from '../components/index';
import TopNav from '../components/TopNav/TopNav';
function AllCoursesPage() {
  return (
    <>
      <HasAccess
        roles={[
          USHG_HQ_APP_ROLE.ENTERPRISE_LEADER,
          USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER,
          USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
          USHG_HQ_APP_ROLE.INDIVIDUAL_USER,
          USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER,
        ]}
        Fallback={null}
      >
        <div className="w-full">
          <Courses />
        </div>
      </HasAccess>
      <HasAccess roles={[USHG_HQ_APP_ROLE.USHG_ADMIN]} Fallback={null}>
        <div className="flex w-full flex-col">
          <div>
            <TopNav />
          </div>
          <div className="p-5">
            <CoursesTableMain />
          </div>
        </div>
      </HasAccess>
    </>
  );
}

export default AllCoursesPage;
