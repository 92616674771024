import ListIndividualTableMain from '../components/ListIndividualTableMain';
import TopNavBar from '../components/TopNavBar';
import { SideBarLayoutPageContentWrapper } from '../../../components/Wrapper';

function ListIndividualPage() {
  return (
    <main id="main-content" className="flex w-full flex-col items-center gap-5">
      <TopNavBar />
      <SideBarLayoutPageContentWrapper>
        <ListIndividualTableMain className="w-full" />
      </SideBarLayoutPageContentWrapper>
    </main>
  );
}

export default ListIndividualPage;
