import * as yup from 'yup';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import { Label } from '../../../../../components/FormElements';
import Select from 'react-select';
import React, { useState } from 'react';
import { ENTERPRISE_USER_ROLES } from '../../../../../constants';
import CreatableSelect from 'react-select/creatable';
import useGetTeamsQuery from '../../../hooks/useGetTeamsQuery';
import Dialog from '../../../../../components/Dialog';
import { Button } from '../../../../../components/Buttons';
import useUpdateRoleOrTeamMutation from '../../../hooks/useUpdateRoleOrTeamMutation';
import { BasicSpinner } from '../../../../../components/Spinners';
import Alert from '../../../../../components/Alert';
import axios from 'axios';
import { UserUpdateRolePayloadType } from '../../../api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { fieldHasError } from '../../../../../utils/react-hook-form';
import ErrorMessage from '../../../../../components/FormElements/ErrorMessage';
import IconWithModal from '../../../../../components/IconWithModal';
import RoleDescription from '../../../../shared/components/RoleDescription';
import { ArrowClockwise, Question } from 'phosphor-react';
import { queryClient } from '../../../../../config/react-query';
import { showToast } from '../../../../shared/helpers/toast';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import { customStylesOne } from '../../../../../styles/modalCustomStyle';
import { useStrapiSharedData } from '../../../../shared/hooks/useStrapiSharedData';

type props = {
  currentRole: string;
  setIsModalOpen: any;
  username: string;
  userRoleUpdate: Record<string, any>;
  userTeamChange: Record<string, any>;
};

type schemaProps = {
  userRoleRequiredError: string;
  roleFieldLabel: string;
  teamDetailRequiredError: string;
  teamFieldLabel: string;
  learnerTeamFieldLabel: string;
};

const getSchema = ({
  userRoleRequiredError,
  roleFieldLabel,
  teamDetailRequiredError,
}: schemaProps) => {
  return yup.object({
    role: yup.string().required(userRoleRequiredError).label(roleFieldLabel),
    team: yup.object().when('role', {
      is: (role: string) =>
        role === ENTERPRISE_USER_ROLES.MANAGER || role === ENTERPRISE_USER_ROLES.LEARNER,
      then: yup.object().required(teamDetailRequiredError),
    }),
  });
};

const UserRoleUpdateModal = ({
  currentRole,
  setIsModalOpen,
  username,
  userRoleUpdate,
  userTeamChange,
}: props) => {
  const {
    roleInputPlaceHolder,
    teamInputLabel,
    roleInputLabel,
    teamFieldLabel,
    teamInputPlaceHolder,
    teamDetailRequiredError,
    roleFieldLabel,
    userRoleRequiredError,
    cancelButtonText,
    updateRoleButtonText,
    SuccessMessageHead,
    SuccessMessageBody,
    teamsFetchErrorMessage,
  } = userRoleUpdate;
  const {
    teamInputLabel: learnerTeamInputLabel,
    teamFieldLabel: learnerTeamFieldLabel,
    teamInputPlaceHolder: learnerTeamInputPlaceHolder,
  } = userTeamChange;
  const strapiError: any = useStrapiDataHelper();
  const strapiErrorMessage = strapiError.errorsMap;

  const userRoleUpdateSchema = getSchema({
    userRoleRequiredError,
    roleFieldLabel,
    teamDetailRequiredError,
    teamFieldLabel,
    learnerTeamFieldLabel,
  });
  const { isLoading, isError, error, mutate } = useUpdateRoleOrTeamMutation();
  const {
    roleDescription,
    dropdownPlaceholderMessages: { loadingMessage, noOptionsMessage },
  } = useStrapiSharedData();
  const [alertError, setAlertError] = useState<string>();
  const {
    register,
    setValue,
    trigger,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(userRoleUpdateSchema) });
  const onSuccess = (role: string) => {
    showToast({
      variant: 'success',
      title: SuccessMessageHead,
      body: `${SuccessMessageBody} ${role}`,
    });
    setIsModalOpen(false);
    queryClient.invalidateQueries({
      queryKey: [
        {
          scope: 'organization',
          item: 'get-members',
        },
      ],
    });
    queryClient.invalidateQueries({
      queryKey: [
        {
          scope: 'teams',
          items: 'TeamMembers',
        },
      ],
    });
  };
  const onSubmit: any = (data: yup.InferType<typeof userRoleUpdateSchema>) => {
    let payload = {} as UserUpdateRolePayloadType;
    const selectedRole = data.role; // New role you're transitioning to

    if (selectedRole === ENTERPRISE_USER_ROLES.LEARNER) {
      payload = {
        username,
        role: selectedRole,
        teamId: data.team.value,
      };
    } else if (selectedRole === ENTERPRISE_USER_ROLES.MANAGER) {
      // Transitioning to Manager: Send both teamId and teamName
      payload = {
        username,
        role: selectedRole,
        teamId: data.team.value,
        teamName: data.team.label,
      };
    } else {
      // Transitioning to other roles (Leader/Admin), no teamId needed unless from Learner
      payload = {
        username,
        role: selectedRole,
      };
    }

    // Call the mutation with the prepared payload
    mutate(payload, {
      onSuccess: () => onSuccess(selectedRole),
      onError: (error) => {
        if (axios.isAxiosError(error)) {
          const data: any = error.response?.data;
          switch (data?.errorCode) {
            case 'TEAM_REQUIRES_ATLEAST_ONE_MANAGER':
              setAlertError(strapiErrorMessage['ONLY_MANAGER_CHANGE_RESTRICTED']);
              break;
            default:
              setAlertError(data?.errorMessage);
            // #markedforlookup
          }
        }
      },
    });
  };

  const roleDropDown = roleDescription.roleDescriptions
    .filter(
      (dropdown: { description: string; label: string; slug: string }) =>
        dropdown.slug !== currentRole
    )
    .map((dropdown: { description: string; label: string; slug: string }) => {
      return {
        label: dropdown.label,
        value: dropdown.slug,
      };
    });

  const rolesInDropDownOptions: string[] = roleDescription.roleDescriptions
    .filter(
      (dropdown: { description: string; label: string; slug: string }) =>
        dropdown.slug !== currentRole
    )
    .map((dropdown: { description: string; label: string; slug: string }) => dropdown.slug);

  const {
    data,
    isLoading: isLoadingTeams,
    refetch,
    isError: isErrorTeams,
    isRefetching: isReFetchingTeams,
  } = useGetTeamsQuery({
    includeSubTeams: true,
    enabled:
      getValues(roleFieldLabel) === ENTERPRISE_USER_ROLES.MANAGER ||
      getValues(roleFieldLabel) === ENTERPRISE_USER_ROLES.LEARNER,
  });

  return (
    <>
      {isError && error && <Alert variant="error" message={alertError} />}
      <form
        onSubmit={(e) => {
          handleSubmit(onSubmit)(e);
        }}
      >
        {/* Select a role */}
        <div>
          <div className="flex flex-row items-center gap-1">
            <Label htmlFor={roleFieldLabel} label={roleInputLabel} />
            <IconWithModal
              icon={<Question size="14px" className="text-zinc-400" />}
              content={<RoleDescription roles={rolesInDropDownOptions} />}
              triggerClassName="max-w-max px-0.5"
              containerClassName="max-h-[80vh] overflow-y-auto z-[100]"
              overlayClassName={'z-[80] bg-black/40'}
            />
          </div>
          <Select
            id={roleInputLabel}
            loadingMessage={() => loadingMessage}
            noOptionsMessage={() => noOptionsMessage}
            options={roleDropDown}
            placeholder={roleInputPlaceHolder}
            styles={customStylesOne}
            classNames={{
              control: () => 'mt-0.5',
            }}
            aria-invalid={fieldHasError(errors, roleFieldLabel) ? 'true' : 'false'}
            {...register(roleFieldLabel)}
            onChange={(role: any) => {
              setValue('role', role.value);
              trigger(roleFieldLabel);
            }}
          />
          <HookFormErrorMessage
            name="role"
            errors={errors}
            render={({ message }) => <ErrorMessage message={message} />}
          />
        </div>
        {/* Select a team */}
        {getValues(roleFieldLabel) === ENTERPRISE_USER_ROLES.MANAGER && (
          <div className="mt-2">
            <div className="flex flex-col justify-between xs:flex-row">
              <Label htmlFor={`change-team_${teamFieldLabel}`} label={teamInputLabel} />
              {isErrorTeams && (
                <div className="flex flex-row items-center gap-1">
                  <p className="text-xs font-normal text-red-500">{teamsFetchErrorMessage}</p>
                  <ArrowClockwise
                    onClick={() => refetch()}
                    size={14}
                    weight="light"
                    color="#a1a1aa"
                    className="cursor-pointer hover:opacity-50"
                  />
                </div>
              )}
            </div>
            <CreatableSelect
              id="team"
              options={data}
              isLoading={isLoadingTeams || isReFetchingTeams}
              placeholder={teamInputPlaceHolder}
              styles={customStylesOne}
              classNames={{
                control: () => 'mt-0.5',
              }}
              aria-invalid={fieldHasError(errors, teamFieldLabel) ? 'true' : 'false'}
              {...register(teamFieldLabel)}
              onChange={(team) => {
                setValue('team', team);
                trigger(teamFieldLabel);
              }}
            />
            <HookFormErrorMessage
              name="team"
              errors={errors}
              render={({ message }) => <ErrorMessage message={message} />}
            />
          </div>
        )}
        {getValues(roleFieldLabel) === ENTERPRISE_USER_ROLES.LEARNER && (
          <div className="mt-2">
            <div className="flex flex-col justify-between xs:flex-row">
              <Label htmlFor={`${learnerTeamFieldLabel}`} label={learnerTeamInputLabel} />
              {isErrorTeams && (
                <div className="flex flex-row items-center gap-1">
                  <p className="text-xs font-normal text-red-500">{teamsFetchErrorMessage}</p>
                  <ArrowClockwise
                    onClick={() => refetch()}
                    size={14}
                    weight="light"
                    color="#a1a1aa"
                    className="cursor-pointer hover:opacity-50"
                  />
                </div>
              )}
            </div>
            <Select
              id="team"
              loadingMessage={() => loadingMessage}
              noOptionsMessage={() => noOptionsMessage}
              options={data}
              isLoading={isLoadingTeams || isReFetchingTeams}
              placeholder={learnerTeamInputPlaceHolder}
              styles={customStylesOne}
              classNames={{
                control: () => 'mt-0.5',
              }}
              aria-invalid={fieldHasError(errors, learnerTeamFieldLabel) ? 'true' : 'false'}
              {...register(learnerTeamFieldLabel)}
              onChange={(team: any) => {
                setValue('team', team);
                trigger(learnerTeamFieldLabel);
              }}
            />
            <HookFormErrorMessage
              name="team"
              errors={errors}
              render={({ message }) => <ErrorMessage message={message} />}
            />
          </div>
        )}
        <div className="mt-5 flex flex-col items-center justify-end xs:flex-row">
          <Dialog.Close
            type="button"
            className="rounded bg-transparent px-[30px] py-2.5 text-center text-sm font-medium text-white"
            onClick={() => setIsModalOpen(false)}
          >
            {cancelButtonText}
          </Dialog.Close>
          <Button
            className="flex items-center gap-1 rounded bg-base-brand px-[30px] py-2.5 text-center text-sm font-medium text-white disabled:opacity-80"
            type="submit"
            disabled={isLoading || isError || isErrorTeams}
          >
            <span>{updateRoleButtonText}</span>
            {isLoading && <BasicSpinner className="!m-0 h-4 w-4 text-zinc-100" />}
          </Button>
        </div>
      </form>
    </>
  );
};

export default UserRoleUpdateModal;
