import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { VideoController } from '../../../../../../components/MediaControls';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import { RootState } from '../../../../../../store';
import { generateCDNMediaAssetURLWithKey } from '../../../../../shared/helpers';
import { useStrapiCoursesData } from '../../../../hooks/useStrapiCourseData';
import useUpdateModuleLastAccessedMutation from '../../../../hooks/useUpdateModuleLastAccessedMutation';
import { setCurrentModuleIndex, setCurrentModuleDetails } from '../../slice/SingleCourse.slice';
import { SingleCourseDisplayProps } from '../../types/SingleCourseTypes';
import CourseDetails from '../CourseDetailsComponent/CourseDetails';
import CourseModulesTab from '../CourseModulesTab/CourseModulesTab';
import ModulesComponent from '../ModulesComponent/ModulesComponent';
import { CourseModule } from '../ModulesComponent/types/moduleTypes';
import ReviewComponent from '../ReviewComponent/ReviewComponent';
import SimilarCoursesComponent from '../CourseWrapper/CourseWrapperComponent';
import SpeakerComponent from '../SpeakerComponent/SpeakerComponent';
import PotentialBadges from '../BadgesDisplay/PotentialBadges';

const SingleCourseDisplay = ({
  courseData,
  modulesData,
  speakersQuery,
  similarCoursesQuery,
}: SingleCourseDisplayProps) => {
  const { id } = useParams();
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const singleCourseSelector = (state: RootState) => state.singleCourse;
  const { currentModuleIndex } = useAppSelector(singleCourseSelector);
  const {
    singleCourseTabs: { courseDetails, modules },
  } = useStrapiCoursesData();
  const COURSE_DETAILS = courseDetails;
  const MODULES = modules;
  // Last Accessed Module Mutation
  const moduleLastAccessedMutation = useUpdateModuleLastAccessedMutation();
  // state
  const [currentCourseTab, setCurrentCourseTab] = useState<string>(COURSE_DETAILS);
  // Course Tab State
  const changeCoursesTab = (tabSection: string) => {
    setCurrentCourseTab(tabSection);
  };
  // Update index when another module is selected
  const moduleClickHandler = (item: CourseModule, index: number) => {
    if (currentModuleIndex != index && modulesData && modulesData[index]) {
      appDispatch(setCurrentModuleIndex(index));
      appDispatch(
        setCurrentModuleDetails({
          id: modulesData[index].id,
          name: modulesData[index].name,
          type: modulesData[index].type,
          mediaUrl: modulesData[index].media,
          description: modulesData[index].description,
          pictureUrl: modulesData[index].thumbnail,
          closedCaptionsUrls: modulesData[index].closedCaptionsUrls,
          status: modulesData[index].status,
          seekableDuration: modulesData[index].watchedDuration * 60,
          startFromDuration: modulesData[index].leftOffDuration * 60,
        })
      );
      moduleLastAccessedMutation.mutate(modulesData[index].id, {
        onError: (error) => {
          console.log(error);
        },
      });
      navigate('modules');
    }
  };
  const { data: similarCoursesData, isError, isLoading } = similarCoursesQuery;
  const { speaker, courseDetails: strapiCourseDetails } = useStrapiCoursesData();
  const { similarCoursesLabel } = strapiCourseDetails;
  return (
    <div className="flex flex-col gap-8">
      {/* Video Section */}
      <div className="w-full rounded">
        <VideoController
          className="aspect-video w-full bg-black object-contain sm:aspect-[3/1.2]"
          thumbnail={generateCDNMediaAssetURLWithKey({ key: courseData.thumbnail })}
          video={
            courseData.videoPreview && courseData.videoPreview.length !== 0
              ? generateCDNMediaAssetURLWithKey({ key: courseData.videoPreview })
              : undefined
          }
        />
      </div>
      <div className="flex w-full flex-col gap-8 lg:flex-row">
        {/* Left Section  */}
        <div className="flex flex-col gap-8 lg:flex-[2]">
          <ReviewComponent
            rating={courseData.rating}
            reviewsCount={courseData.reviewsCount}
            duration={courseData.duration}
            modules={courseData.modules}
            dueDate={courseData.dueDate}
          />
          {/* Tab Bar */}
          <CourseModulesTab
            currentTab={currentCourseTab}
            tabHeaders={[COURSE_DETAILS, MODULES]}
            changeTab={changeCoursesTab}
          />
          {/* Tab Page */}
          {currentCourseTab === COURSE_DETAILS && (
            <CourseDetails
              courseDetails={{
                description: courseData.courseDescription,
                category: courseData.category,
                tags: courseData.tags,
                objectives: courseData.learnerGist,
              }}
              similarCoursesQuery={similarCoursesQuery}
            />
          )}
          {currentCourseTab === MODULES && (
            <ModulesComponent moduleClickHandler={moduleClickHandler} modulesData={modulesData} />
          )}
        </div>
        {/* Right Section - Speakers and Badges */}
        <div className="flex flex-col gap-6 lg:max-w-[21.75rem] lg:flex-1">
          {/* SPEAKERS SECTION */}
          <SpeakerComponent speakersQuery={speakersQuery} title={speaker.title} />
          {/* BADGES SECTION */}
          {id && <PotentialBadges id={parseInt(id)} />}
        </div>
        {/* Similar Courses */}
        {similarCoursesData && similarCoursesData.length > 0 && (
          <div className="flex flex-col lg:hidden">
            <SimilarCoursesComponent
              coursesData={similarCoursesData}
              label={similarCoursesLabel}
              isLoading={isLoading}
              isError={isError}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleCourseDisplay;
