import { Navigate } from 'react-router-dom';
import { USHG_HQ_APP_ROLE } from '../../../constants';
import { useHasAccess } from '../../../hooks';
import Badges from '../components/Badges';

function BadgeMain() {
  const canViewAllMemberBadges = useHasAccess([
    USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
    USHG_HQ_APP_ROLE.ENTERPRISE_LEADER,
    USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER,
  ]);

  if (!canViewAllMemberBadges) {
    return <Navigate to={`/dashboard`} />;
  }
  return <Badges showBackButton={true} />;
}
export default BadgeMain;
