import React, { useEffect, useMemo, useState } from 'react';
import EarnedBadgesDisplay from '../../dashboard/components/common/EarnedBadgesDisplay';
import { useNavigate, useParams } from 'react-router-dom';
import useUserStatsQuery from '../hooks/useUserStatsQuery';
import { PartialUsersStatsFilter } from '../types/interface';
import { FadeLoader } from 'react-spinners';
import { ErrorDisplay } from '../../../components/DataTables';
import useCourseHistoryQuery from '../hooks/useCourseHistoryQuery';
import SinglePersonTopNav from '../components/TopNav/SinglePersonTopNav';
import { useStrapiTeamData } from '../hooks/useStrapiTeamData';
import {
  SinglePersonProfileStatistics,
  SinglePersonCourseHistory,
  SinglePersonAssignedCourse,
  IndividualLearnerReports,
} from '../components/SinglePersonProfile';
import TabWidgets from '../components/TabWidgets';
import { TabWidgetType } from '../types';
import { ReportsFilterProvider } from '../../shared/providers';
import { useStrapiDataHelper } from '../../../hooks/useStrapiData';
import ProfileEmptyState from './ProfileEmptyState';
import { useLanguage } from '../../shared/context/languageContext';

const SinglePersonProfilePage = () => {
  const { singlePersonHeader } = useStrapiTeamData();
  const { language } = useLanguage();
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  const [currentTab, setCurrentTab] = useState<TabWidgetType | undefined>(undefined);
  const [tabWidgetData, setTabWidgetData] = useState<TabWidgetType[]>([]);

  const { id } = useParams() ?? undefined;
  const username: PartialUsersStatsFilter = useMemo(() => {
    return {
      username: id,
    };
  }, [id]);

  // Update tab data whenever the locale or Strapi data changes
  useEffect(() => {
    const newTabData = [
      {
        id: '1',
        text: singlePersonHeader?.profile,
        isSelected: !!currentTab && currentTab.id === '1',
      },
      {
        id: '2',
        text: singlePersonHeader?.performance,
        isSelected: !!currentTab && currentTab.id === '2',
      },
    ];
    setTabWidgetData(newTabData);

    setCurrentTab(newTabData[0]);
  }, [singlePersonHeader, language]);

  const { singlePerson } = useStrapiTeamData();
  const { courseHistoryTitle } = singlePerson;
  const courseHistory = useCourseHistoryQuery({ username });

  const usersStats = useUserStatsQuery({ username });
  const navigate = useNavigate();

  const errorResponseData = (usersStats.error as any)?.response?.data;
  const notExistError =
    usersStats.isError && !usersStats.data && errorResponseData?.errorCode == 'USER_NOT_FOUND';

  if (notExistError) {
    return (
      <div className="flex w-full">
        <ProfileEmptyState
          message={
            <div className="flex flex-col items-center gap-y-8">
              <span>{"We couldn't find any results."}</span>
              <button
                className="w-min whitespace-nowrap rounded-lg border border-zinc-800 bg-zinc-900 py-2 px-4"
                onClick={() => navigate('/members/all')}
              >
                {'Back to All Members'}
              </button>
            </div>
          }
        />
      </div>
    );
  }

  return !courseHistory.isLoading && !usersStats.isLoading ? (
    <main id="main-content" className="flex w-full flex-col items-center gap-5">
      <SinglePersonTopNav
        title={usersStats?.data?.fullName ? usersStats?.data?.fullName : username.username}
        jobTitle={usersStats?.data?.jobTitle}
        pictureUrl={usersStats?.data?.pictureUrl}
      />
      <div className="w-full px-3 xs:px-4 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9">
        <div className="grid grid-flow-col grid-cols-12 gap-x-3 gap-y-2 sm:gap-y-3 lg:gap-x-[13px] lg:gap-y-2 xl:gap-y-5">
          {usersStats.error ? (
            <div className="flex h-full w-full items-center justify-center text-zinc-200">
              <ErrorDisplay
                message={strapiErrorMessage['DATA_ERROR']}
                refetch={usersStats.refetch}
                isRefetching={usersStats.isRefetching}
              />
            </div>
          ) : !usersStats.isLoading && usersStats.data ? (
            <SinglePersonProfileStatistics
              className="col-span-12 col-start-1 row-start-1 lg:col-span-9"
              stats={usersStats.data}
            />
          ) : (
            <div className="flex h-full w-full items-center justify-center">
              {usersStats.isLoading && <FadeLoader color="#E4E4E7" width="3px" />}
            </div>
          )}
          <EarnedBadgesDisplay
            className="col-span-12 col-start-1 row-span-2 row-start-3 h-max overflow-hidden  shadow-xl lg:col-span-3 lg:col-start-10 lg:row-start-1 lg:-mt-20"
            showBadgeName={true}
          />
          <SinglePersonCourseHistory
            className="col-span-12 col-start-1 row-start-2 overflow-hidden lg:col-span-9"
            statsCardTitle={courseHistoryTitle}
            courseHistory={courseHistory}
          />
          <TabWidgets
            className="col-span-12 col-start-1 row-start-6 overflow-hidden lg:col-span-9 lg:row-start-3"
            selectedWidget={currentTab}
            setSelectedWidget={setCurrentTab}
            widgetData={tabWidgetData}
          />

          {currentTab?.text === singlePersonHeader?.profile && (
            <SinglePersonAssignedCourse
              className="col-span-12 col-start-1 row-start-7 lg:row-start-4"
              username={username}
            />
          )}

          {currentTab?.text === singlePersonHeader?.performance && (
            <ReportsFilterProvider>
              <div className="col-span-12 col-start-1 row-start-[8] lg:row-start-4">
                <IndividualLearnerReports />
              </div>
            </ReportsFilterProvider>
          )}
        </div>
      </div>
    </main>
  ) : (
    <div className="flex h-[16.98rem] w-full items-center justify-center">
      <FadeLoader color="#E4E4E7" width="3px" />
    </div>
  );
};

export default SinglePersonProfilePage;
