import { lazy } from 'react';
import { USHG_HQ_APP_ROLE } from '../../../constants';
import HasAccess from '../../shared/components/HasAccess';

// Lazy Load the Enterprise Admin and USHG Admin Requests Page
const EnterpriseAdminRequestsPage = lazy(() => import('./EnterpriseAdminRequestsPage'));
const USHGAdminRequestsPage = lazy(() => import('./USHGAdminRequestsPage'));

const RequestsPage = () => {
  return (
    <div>
      <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN]} Fallback={null}>
        <EnterpriseAdminRequestsPage />
      </HasAccess>
      <HasAccess roles={[USHG_HQ_APP_ROLE.USHG_ADMIN]} Fallback={null}>
        <USHGAdminRequestsPage />
      </HasAccess>
    </div>
  );
};

export default RequestsPage;
