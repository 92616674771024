import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Dialog from '../../../../components/Dialog';
import { TextAreaInput, TextInput } from '../../../../components/FormElements';
import { GET_SPEAKER_QUERY_KEY, QUERY_KEYS, SPEAKER_ACTION } from '../../constants/common';
import { validateMediaFile } from '../../../shared/helpers/media.helper';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CreateSpeakerInMultiLangPayload,
  CreateSpeakerPayload,
  GetSpeakerByIdResponse,
  ListSpeakersResults,
  UpdateSpeakerPayload,
} from '../../types/GetSpeakerApiResponse';
import { fieldHasError } from '../../../../utils/react-hook-form';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import ErrorMessage from '../../../../components/FormElements/ErrorMessage';
import {
  useCreateMutation,
  useCreateSpeakerInMultiLangMutation,
  useUpdateMutation,
} from '../../hooks/useCreateAndUpdateMutation';
import { Toast } from '../../../../components/Toast';
import toast from 'react-hot-toast';
import { BasicSpinner } from '../../../../components/Spinners';
import MediaUploadDropZone from '../../../../components/DropZone/MediaUploadDropZone';
import { useSpeakerStrapiData } from '../../hooks/useSpeakerStrapiData';
import useUploadImageQuery from '../../../shared/hooks/useUploadImageQuery';
import { UPLOAD_REQUEST_TYPE } from '../../../shared/constants/imageUpload';
import Select from '../../../../components/Select';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../../constants/user';
import { ERROR_CODE } from '../../constants';
import { generateCDNMediaAssetURLWithKey } from '../../../shared/helpers';
import { useQueryClient } from '@tanstack/react-query';
import cn from '../../../../utils/cn';

interface Props {
  type: 'ADD' | 'EDIT' | 'MANAGE';
  modalTitle: string;
  speakerDefaultData?: ListSpeakersResults | GetSpeakerByIdResponse;
  formActionButtonLabel: string;
  showLanguageDropdown?: boolean;
  mappedOptions?: { display: string; value: string }[];
  selectedLanguage?: string;
  handleLanguageChange?: (newLanguage: string) => void;
  errorType?: string | null;
  setErrorType?: (error: string | null) => void;
  isSpeakerDataLoading?: boolean;
}

function schema(createAndEditModalStrapiData: {
  firstNameMinValue: number;
  firstNameMinError: string;
  firstNameMaxValue: number;
  firstNameMaxError: string;
  firstNameRequired: string;
  firstNameLabel: string;
  lastNameMinValue: number;
  lastNameMinError: string;
  lastNameMaxValue: number;
  lastNameMaxError: string;
  lastNameRequired: string;
  lastNameLabel: string;
  descriptionMinValue: number;
  descriptionMinError: string;
  descriptionMaxValue: number;
  descriptionMaxError: string;
  descriptionRequired: string;
  descriptionLabel: string;
  positionRequired: string;
  positionMinValue: number;
  positionMinError: string;
  positionMaxValue: number;
  positionMaxError: string;
  positionLabel: string;
  speakerImageRequired: string;
  speakerImageLabel: string;
}) {
  const {
    firstNameMinValue,
    firstNameMinError,
    firstNameMaxValue,
    firstNameMaxError,
    firstNameRequired,
    firstNameLabel,
    lastNameMinValue,
    lastNameMinError,
    lastNameMaxValue,
    lastNameMaxError,
    lastNameRequired,
    lastNameLabel,
    descriptionMinValue,
    descriptionMinError,
    descriptionMaxValue,
    descriptionMaxError,
    descriptionRequired,
    descriptionLabel,
    positionRequired,
    positionMinValue,
    positionMinError,
    positionMaxValue,
    positionMaxError,
    positionLabel,
    speakerImageRequired,
    speakerImageLabel,
  } = createAndEditModalStrapiData;
  return yup.object({
    firstName: yup
      .string()
      .trim()
      .min(firstNameMinValue, firstNameMinError)
      .max(firstNameMaxValue, firstNameMaxError)
      .required(firstNameRequired)
      .label(firstNameLabel),
    lastName: yup
      .string()
      .trim()
      .min(lastNameMinValue, lastNameMinError)
      .max(lastNameMaxValue, lastNameMaxError)
      .required(lastNameRequired)
      .label(lastNameLabel),
    description: yup
      .string()
      .trim()
      .min(descriptionMinValue, descriptionMinError)
      .max(descriptionMaxValue, descriptionMaxError)
      .required(descriptionRequired)
      .label(descriptionLabel),
    position: yup
      .string()
      .trim()
      .required(positionRequired)
      .min(positionMinValue, positionMinError)
      .max(positionMaxValue, positionMaxError)
      .label(positionLabel),
    speakerImage: yup.string().required(speakerImageRequired).label(speakerImageLabel),
  });
}

function CreateAndEditNewSpeaker({
  type,
  modalTitle,
  speakerDefaultData,
  formActionButtonLabel,
  mappedOptions,
  selectedLanguage,
  handleLanguageChange,
  errorType,
  setErrorType,
  isSpeakerDataLoading,
  showLanguageDropdown = false,
}: Props) {
  const queryClient = useQueryClient();

  const { modalStrapiData } = useSpeakerStrapiData();
  const speakerFormSchema = schema(modalStrapiData);
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    trigger,
    reset,
    control,
    formState: { errors },
  } = useForm<SPEAKER_MODAL_FORM_DATA>({
    mode: 'onSubmit',
    resolver: yupResolver(speakerFormSchema),
  });

  type SPEAKER_MODAL_FORM_DATA = yup.InferType<typeof speakerFormSchema>;

  // State to manage field disabling
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false); // Track refetching state

  // create, update, or delete of speaker action in progress
  const [actionInProgress, setActionInProgress] = useState<keyof typeof SPEAKER_ACTION | null>(
    null
  );

  // state

  // Mutations
  const createSpeaker = useCreateMutation();
  const createSpeakerInMultiLang = useCreateSpeakerInMultiLangMutation();
  const updateSpeaker = useUpdateMutation();

  const {
    toastMessage: {
      createSpeakerSuccess,
      createSpeakerFailed,
      updateSpeakerSuccess,
      updateSpeakerFailed,
    },
  } = useSpeakerStrapiData();

  const dialogCloseElement = useRef<HTMLButtonElement>(null);
  const dialogClose = () => {
    if (dialogCloseElement.current) {
      dialogCloseElement.current.click();
    }
  };

  const memoizedSpeakerData = useMemo(() => speakerDefaultData, [speakerDefaultData]);

  useEffect(() => {
    const setSpeakerValues = () => {
      if (!memoizedSpeakerData) return;

      // Set common fields
      setValue('firstName', memoizedSpeakerData.firstName || '');
      setValue('lastName', memoizedSpeakerData.lastName || '');
      setValue('speakerImage', memoizedSpeakerData.picture || '');

      // Set additional fields if in edit mode
      if (type !== 'MANAGE' || errorType !== ERROR_CODE.SPEAKER_NOT_FOUND) {
        setValue('description', memoizedSpeakerData.bio || '');
        setValue('position', memoizedSpeakerData.jobTitle || '');
      }
    };

    reset();

    if (memoizedSpeakerData) {
      if (type === 'MANAGE' && errorType === ERROR_CODE.SPEAKER_NOT_FOUND) {
        setIsFieldDisabled(true); // Disable fields in create mode
      } else if (type === 'MANAGE' || type === 'EDIT') {
        setIsFieldDisabled(type === 'MANAGE'); // Disable fields in manage mode, enable in edit mode
      }
      setSpeakerValues(); // Prepopulate fields
    }
  }, [memoizedSpeakerData, errorType, type, reset, setValue]);

  const createSpeakerHandler = async (data: SPEAKER_MODAL_FORM_DATA) => {
    const payload: CreateSpeakerPayload = {
      firstName: data.firstName,
      lastName: data.lastName,
      bio: data.description,
      jobTitle: data.position,
      picture: data.speakerImage,
    };

    setIsRefetching(true);
    setActionInProgress('ADD');
    createSpeaker.mutate(payload, {
      onSuccess: async () => {
        toast.custom(
          (t) => <Toast variant="success" Title={createSpeakerSuccess} toastInstance={t} />,
          { id: 'ADD_NEW_SPEAKER_TOAST_' + Math.floor(Math.random() * 3000).toString() }
        );
        await queryClient.refetchQueries([GET_SPEAKER_QUERY_KEY]);
        setIsRefetching(false);
        dialogClose();
      },
      onError: () => {
        toast.custom(
          (t) => <Toast variant="error" Title={createSpeakerFailed} toastInstance={t} />,
          { id: 'ADD_NEW_SPEAKER_TOAST_' + Math.floor(Math.random() * 3000).toString() }
        );
        setIsRefetching(false);
        dialogClose();
      },
      onSettled: () => {
        setActionInProgress(null);
      },
    });
  };

  const createSpeakerInMultiLangHandler = async (data: SPEAKER_MODAL_FORM_DATA) => {
    if (!speakerDefaultData?.speakerId || !selectedLanguage) {
      return;
    }
    const payload: CreateSpeakerInMultiLangPayload = {
      speakerId: speakerDefaultData.speakerId,
      bio: data.description,
      jobTitle: data.position,
      language: selectedLanguage,
    };
    setIsRefetching(true);
    setActionInProgress('ADD');
    createSpeakerInMultiLang.mutate(payload, {
      onSuccess: async (response, variables) => {
        queryClient.setQueryData(
          QUERY_KEYS.getSpeakerById({
            speakerId: variables.speakerId,
            language: variables.language,
          }),
          () => {
            return {
              firstName: response.firstName,
              lastName: response.lastName,
              fullName: response.fullName,
              bio: response.bio,
              jobTitle: response.jobTitle,
              picture: response.picture,
              language: response.language,
              speakerId: response.speakerId,
            };
          }
        );
        setErrorType && setErrorType(null);
        toast.custom(
          (t) => <Toast variant="success" Title={createSpeakerSuccess} toastInstance={t} />,
          {
            id: 'ADD_NEW_SPEAKER_TOAST_MULTI_LANG_' + Math.floor(Math.random() * 3000).toString(),
          }
        );
        setIsRefetching(false);
        dialogClose();
      },
      onError: () => {
        toast.custom(
          (t) => <Toast variant="error" Title={createSpeakerFailed} toastInstance={t} />,
          { id: 'ADD_NEW_SPEAKER_TOAST_MULTI_LANG_' + Math.floor(Math.random() * 3000).toString() }
        );
        setIsRefetching(false);
        dialogClose();
      },
      onSettled: () => {
        setActionInProgress(null);
      },
    });
  };

  const updateSpeakerHandler = async (data: SPEAKER_MODAL_FORM_DATA) => {
    const payload: UpdateSpeakerPayload = {
      firstName: data.firstName,
      lastName: data.lastName,
      jobTitle: data.position,
      picture: data.speakerImage,
      bio: data.description,
      speakerId: speakerDefaultData?.speakerId,
      language: selectedLanguage ?? DEFAULT_PREFERRED_LANGUAGE,
    };
    setIsRefetching(true);
    setActionInProgress('EDIT');
    updateSpeaker.mutate(payload, {
      onSuccess: async (response, variables) => {
        queryClient.setQueryData(
          QUERY_KEYS.getSpeakerById({
            speakerId: variables?.speakerId as number,
            language: variables.language,
          }),
          () => {
            return {
              firstName: response.firstName,
              lastName: response.lastName,
              fullName: response.fullName,
              bio: response.bio,
              jobTitle: response.jobTitle,
              picture: response.picture,
              language: response.language,
              speakerId: response.speakerId,
            };
          }
        );

        if (!selectedLanguage) {
          await queryClient.refetchQueries([GET_SPEAKER_QUERY_KEY]);
        }

        toast.custom(
          (t) => <Toast variant="success" Title={updateSpeakerSuccess} toastInstance={t} />,
          { id: 'UPDATE_SPEAKER_TOAST_' + Math.floor(Math.random() * 3000).toString() }
        );
        setIsRefetching(false);
        dialogClose();
      },
      onError: () => {
        toast.custom(
          (t) => <Toast variant="error" Title={updateSpeakerFailed} toastInstance={t} />,
          { id: 'UPDATE_SPEAKER_TOAST_' + Math.floor(Math.random() * 3000).toString() }
        );
        setIsRefetching(false);
        dialogClose();
      },
      onSettled: () => {
        setActionInProgress(null);
      },
    });
  };

  const [speakerUploader, setSpeakerUploader] = useState<any>(undefined);

  const {
    isProgress: speakerProgress,
    progress: speakerProgressPercent,
    initiating: speakerInitiating,
    isUploaded: speakerUploaded,
    data: speakerData,
    abort: speakerUploadAbort,
  } = useUploadImageQuery({ upload: speakerUploader });

  // State to manage speaker image upload
  const [isSpeakerImageUploaded, setIsSpeakerImageUploaded] = useState(speakerUploaded);

  const onDropSpeaker = useCallback(
    (acceptedFiles: any) => {
      const { error, message, type } = validateMediaFile(
        acceptedFiles,
        10,
        'image',
        'Speaker Image'
      );
      if (error) {
        setError('speakerImage', { message });
      } else {
        const uploader = {
          fileType: type,
          requestType: UPLOAD_REQUEST_TYPE.SINGLE_OBJECT,
          file: acceptedFiles[0],
          module: 'speaker',
          fileExtension: acceptedFiles[0].type,
        };
        setSpeakerUploader(uploader);
      }
    },
    [setError]
  );

  useEffect(() => {
    if (speakerData) {
      setValue('speakerImage', speakerData);
      trigger('speakerImage');
    }
  }, [speakerData, setValue, trigger]);

  useEffect(() => {
    setIsSpeakerImageUploaded(speakerUploaded);
  }, [speakerUploaded]);

  const onCancelUploadImage = () => {
    if (speakerUploader) {
      speakerUploadAbort.abort();
    }
  };

  return (
    <div className="rounded bg-card-bg p-5 text-white">
      <Dialog.Title className="text-lg font-semibold">{modalTitle}</Dialog.Title>
      {showLanguageDropdown && speakerDefaultData && (
        <Select
          aria-label="Language options"
          options={mappedOptions?.length ? mappedOptions : []}
          value={selectedLanguage}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => handleLanguageChange?.(e.target.value)}
          disabledOptions={type === 'MANAGE' ? [DEFAULT_PREFERRED_LANGUAGE] : []}
          className="ml-auto"
          disabled={!!actionInProgress}
        />
      )}
      {isSpeakerDataLoading ? (
        <div className="flex h-full w-full items-center justify-center py-4">
          <BasicSpinner className="h-[30vh] text-white" />
        </div>
      ) : (
        (speakerDefaultData || type === 'ADD') && (
          <form
            className="my-5 flex flex-col gap-4"
            onSubmit={(e) => {
              e.preventDefault();
              // e.preventDefault();
              if (type === 'ADD') {
                handleSubmit(createSpeakerHandler)(e);
              } else if (type === 'EDIT') {
                handleSubmit(updateSpeakerHandler)(e);
              } else if (type === 'MANAGE') {
                if (errorType === ERROR_CODE.SPEAKER_NOT_FOUND) {
                  handleSubmit(createSpeakerInMultiLangHandler)(e);
                } else {
                  handleSubmit(updateSpeakerHandler)(e);
                }
              }
            }}
          >
            <div className="flex w-full flex-row gap-3">
              <div className={cn('basis-full md:basis-1/2', isFieldDisabled && 'opacity-50')}>
                <label
                  className="mb-2 block text-xs font-medium normal-case tracking-wide text-zinc-400"
                  htmlFor="speaker_first_name"
                >
                  {modalStrapiData.firstNameLabel}
                </label>
                <div className="relative">
                  <TextInput
                    className="block h-9 w-full appearance-none rounded border-[1px] border-zinc-700 bg-zinc-900 py-3 px-4 leading-tight text-gray-50 placeholder:text-xs"
                    id="speaker_first_name"
                    type="text"
                    placeholder={modalStrapiData.firstNamePlaceholder}
                    aria-invalid={fieldHasError(errors, 'firstName') ? 'true' : 'false'}
                    hasError={fieldHasError(errors, 'firstName')}
                    disabled={isFieldDisabled}
                    {...register('firstName')}
                  />
                  <HookFormErrorMessage
                    name="firstName"
                    errors={errors}
                    render={({ message }) => <ErrorMessage message={message} />}
                  />
                </div>
              </div>
              <div className={cn('basis-full md:basis-1/2', isFieldDisabled && 'opacity-50')}>
                <label
                  className="mb-2 block text-xs font-medium normal-case tracking-wide text-zinc-400"
                  htmlFor="speaker_last_name"
                >
                  {modalStrapiData.lastNameLabel}
                </label>
                <div className="relative">
                  <TextInput
                    className="block h-9 w-full appearance-none rounded border-[1px] border-zinc-700 bg-zinc-900 py-3 px-4 leading-tight text-gray-50 placeholder:text-xs"
                    id="speaker_last_name"
                    type="text"
                    placeholder={modalStrapiData.lastNamePlaceholder}
                    aria-invalid={fieldHasError(errors, 'lastName') ? 'true' : 'false'}
                    hasError={fieldHasError(errors, 'lastName')}
                    disabled={isFieldDisabled}
                    {...register('lastName')}
                  />
                  <HookFormErrorMessage
                    name="lastName"
                    errors={errors}
                    render={({ message }) => <ErrorMessage message={message} />}
                  />
                </div>
              </div>
            </div>
            <div className="w-full">
              <label
                className="mb-2 block text-xs font-medium normal-case tracking-wide text-zinc-400"
                htmlFor="speaker_description"
              >
                {modalStrapiData.descriptionLabel}
              </label>
              <div className="relative">
                <TextAreaInput
                  id="speaker_description"
                  style={{ resize: 'none' }}
                  className="mt-0.5 h-28 w-full rounded-md"
                  placeholder={modalStrapiData.descriptionPlaceholder}
                  aria-invalid={fieldHasError(errors, 'description') ? 'true' : 'false'}
                  hasError={fieldHasError(errors, 'description')}
                  {...register('description')}
                />
                <HookFormErrorMessage
                  name="description"
                  errors={errors}
                  render={({ message }) => <ErrorMessage message={message} />}
                />
              </div>
            </div>
            <div className="w-full">
              <label
                className="mb-2 block text-xs font-medium normal-case tracking-wide text-zinc-400"
                htmlFor="speaker_position"
              >
                {modalStrapiData.positionLabel}
              </label>
              <div className="relative">
                <TextInput
                  className="block h-9 w-full appearance-none rounded border-[1px] border-zinc-700 bg-zinc-900 py-3 px-4 leading-tight text-gray-50 placeholder:text-xs"
                  id="speaker_position"
                  type="text"
                  placeholder={modalStrapiData.positionPlaceholder}
                  aria-invalid={fieldHasError(errors, 'position') ? 'true' : 'false'}
                  hasError={fieldHasError(errors, 'position')}
                  {...register('position')}
                />
                <HookFormErrorMessage
                  name="position"
                  errors={errors}
                  render={({ message }) => <ErrorMessage message={message} />}
                />
              </div>
            </div>
            <div className="w-full">
              <label
                className="mb-2 block text-xs font-medium normal-case tracking-wide text-zinc-400"
                htmlFor="speaker_image"
              >
                {modalStrapiData.imageLabel}
              </label>
              <Controller
                name="speakerImage"
                control={control}
                defaultValue=""
                render={({ field: { value } }) => (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <MediaUploadDropZone
                      fileType="PNG, JPG"
                      maxSize="10mb"
                      loader="progress"
                      onDropCallback={onDropSpeaker}
                      isUploaded={value && value.length > 0 ? true : isSpeakerImageUploaded}
                      initiatingUpload={speakerInitiating}
                      isProgress={speakerProgress}
                      progress={speakerProgressPercent}
                      resetMedia={() => {
                        setValue('speakerImage', '');
                        trigger('speakerImage');
                        setIsSpeakerImageUploaded(false);
                      }}
                      cancel={onCancelUploadImage}
                      isPreviewImage={true}
                      previewImage={
                        value
                          ? generateCDNMediaAssetURLWithKey({
                              key: value,
                            })
                          : undefined
                      }
                      disabled={isFieldDisabled}
                    />
                  </div>
                )}
              />
              <HookFormErrorMessage
                name="speakerImage"
                errors={errors}
                render={({ message }) => <ErrorMessage message={message} />}
              />
            </div>
            <div className="mt-5 flex justify-end">
              <Dialog.Close ref={dialogCloseElement} className="px-7 text-sm font-medium">
                {modalStrapiData.cancelButton}
              </Dialog.Close>
              <button
                type="submit"
                disabled={
                  createSpeaker.isLoading ||
                  createSpeakerInMultiLang.isLoading ||
                  updateSpeaker.isLoading ||
                  isSpeakerDataLoading ||
                  isRefetching
                }
                className="rounded bg-base-brand py-2.5 px-[30px] text-center text-sm font-medium text-white disabled:opacity-80"
              >
                <div className="flex gap-2">
                  <span>{formActionButtonLabel}</span>
                  {(createSpeaker.isLoading ||
                    updateSpeaker.isLoading ||
                    createSpeakerInMultiLang.isLoading ||
                    isSpeakerDataLoading ||
                    isRefetching) && <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />}
                </div>
              </button>
            </div>
          </form>
        )
      )}
    </div>
  );
}

export default CreateAndEditNewSpeaker;
