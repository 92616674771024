import { useQuery } from '@tanstack/react-query';
import { getHQAdminBadges } from '../api/badges';

import { STALE_TIME } from '../constants/USHGAdmin';
import { PartialUSHGHQAdminBadgesFilter } from '../types/badgeManagement';

interface Props {
  filters: PartialUSHGHQAdminBadgesFilter;
}

const useHQAdminBadgesQuery = ({ filters }: Props) => {
  const { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch } =
    useQuery(
      [
        {
          scope: 'get-badges',
          item: 'badges',
          filters: filters,
        },
      ],
      async () => {
        return getHQAdminBadges(filters);
      },
      {
        keepPreviousData: true,
        staleTime: STALE_TIME,
      }
    );

  return { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch };
};

export default useHQAdminBadgesQuery;
