import { useNavigate, useParams } from 'react-router-dom';
import { BasicSpinner } from '../../../../components/Spinners';
import { ERROR_TYPES } from '../../../../constants/error-types.constants';
import { useStrapiDataHelper } from '../../../../hooks/useStrapiData';
import { useModuleBasicDetailsQuery } from '../../hooks';
import { useStrapiHQAdminCoursesData } from '../../hooks/useStrapiHQAdminCourseData';
import { CoursesEmptyState, CoursesErrorState } from '../common';
import { ModuleMain } from './components/ModuleMain';
import PageHeader from './PageHeader';
import { SideBarLayoutPageContentWrapper } from '../../../../components/Wrapper';

const CourseModulePage = () => {
  const pathParams = useParams();

  // get moduleId from params
  const moduleId = parseInt(pathParams.moduleId as string);
  const navigate = useNavigate();

  // query to fetch module basic details
  const moduleBasicDetailsQuery = useModuleBasicDetailsQuery({ moduleId });

  // pull data from strapi helper
  const strapidata = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;

  const displayLoader = moduleBasicDetailsQuery.isLoading && !moduleBasicDetailsQuery.data;
  // Empty State

  const isEmptyState =
    moduleBasicDetailsQuery.data && Object.keys(moduleBasicDetailsQuery).length === 0;

  // Assessment doesnot exist error
  const notExistError = moduleBasicDetailsQuery.errorType && !moduleBasicDetailsQuery.data;

  // Error State
  const errorState = moduleBasicDetailsQuery.isError && !moduleBasicDetailsQuery.data;
  const { loadingMessages, emptyMessages, topNav } = useStrapiHQAdminCoursesData();
  return (
    <div className="flex h-full flex-col">
      {/* Module Details loading */}
      {displayLoader && (
        <div className="flex h-full w-full items-center justify-center bg-black py-4">
          <BasicSpinner className="h-6 w-6 text-white" />
          <span className="text-lg font-normal leading-[24px] text-zinc-50">
            {loadingMessages.moduleLoading}...
          </span>
        </div>
      )}
      {/* Module Details loaded */}
      {moduleId && moduleBasicDetailsQuery.data && !displayLoader && (
        <main id="main-content" className="flex flex-col gap-5">
          <PageHeader moduleName={moduleBasicDetailsQuery.data.name} />
          <SideBarLayoutPageContentWrapper>
            <ModuleMain moduleBasicDetailsQuery={moduleBasicDetailsQuery} />
          </SideBarLayoutPageContentWrapper>
        </main>
      )}
      {/* Empty State */}
      {isEmptyState && !displayLoader && (
        <div className="flex w-full">
          <CoursesEmptyState message={<span>{emptyMessages.emptyModule}</span>} />
        </div>
      )}
      {/* Course doesnot exist error state */}
      {notExistError && (
        <div className="flex w-full">
          <CoursesEmptyState
            message={
              <div className="flex flex-col items-center gap-y-8">
                <span>{emptyMessages.commonEmptyMessage}</span>
                <button
                  className="w-min whitespace-nowrap rounded-lg border border-zinc-800 bg-zinc-900 py-2 px-4"
                  onClick={() => navigate('/manage/courses')}
                >
                  {topNav.backToCourseButton}
                </button>
              </div>
            }
          />
        </div>
      )}
      {/* Error State */}
      {errorState && !notExistError && (
        <div className="flex h-full items-center justify-center">
          <CoursesErrorState
            refetchArray={[moduleBasicDetailsQuery.refetch]}
            isRefetching={moduleBasicDetailsQuery.isRefetching}
            allowsRefetch={true}
            type={ERROR_TYPES.SOMETHING_WENT_WRONG}
            message={strapiErrorMessage['SYSTEM_ERROR']}
          />
        </div>
      )}
    </div>
  );
};

export default CourseModulePage;
