import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import { USHGAPIError } from '../../../types';
import { getUSHGHQBadgeDetails } from '../api/badges';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../constants/user';

interface Props {
  badgeId: number;
  language?: string;
  isEnabled?: boolean;
}

const useUSHGHQBadgeDetailsQuery = ({
  badgeId,
  language = DEFAULT_PREFERRED_LANGUAGE,
  isEnabled = true,
}: Props) => {
  const [errorType, setErrorType] = useState<string | null>();
  const { isInitialLoading, data, isError, refetch, isRefetching, isFetching } = useQuery(
    [
      {
        id: badgeId,
        language,
        scope: 'badge',
        item: 'ushghq-admin-badge-details',
      },
    ],
    async ({ queryKey }) => {
      const { id, language } = queryKey[0];

      return await getUSHGHQBadgeDetails({ id, language });
    },
    {
      enabled: isEnabled && badgeId !== undefined,
      onError: (error) => {
        // If axios error
        if (axios.isAxiosError(error)) {
          const data = error.response?.data;
          if (data) {
            const { errorCode } = data as USHGAPIError;
            setErrorType(errorCode);
          }
        }
      },
    }
  );
  return {
    isInitialLoading,
    data,
    isError,
    refetch,
    isRefetching,
    isFetching,
    errorType,
    setErrorType,
  };
};

export default useUSHGHQBadgeDetailsQuery;
