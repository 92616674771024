import { useCallback, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Divider } from '../../../../../components/Divider';
import UserSocialLogin from '../../../../../components/UserSocialLogin';
import { CognitoAuthChallenge } from '../../../../../constants';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import { useStrapiSharedData } from '../../../../shared/hooks/useStrapiSharedData';
import { loginAuthChallengeContext } from '../../../context';
import UserLoginHeader from '../common/UserLoginHeader';
import { SMSMFA } from './Challenges';
import UserLoginForm from './UserLoginForm';
import UserLoginMainFooter from './UserLoginMainFooter';
import UserLoginMainLayout from './UserLoginMainLayout';

const UserLoginMain = () => {
  // strapi

  const data: any = useStrapiDataHelper();
  const {
    SSO: { appleLogin, googleLogin, orLabel },
  } = useStrapiSharedData();
  const attributes = data?.login.data.attributes;
  const { LoginTitle, LoginDesc } = attributes;

  // strapi

  // context
  const { authChallenge } = useContext(loginAuthChallengeContext);
  // context

  // hooks

  const navigate = useNavigate();
  const location = useLocation();

  // hooks

  // state

  // state

  // callbacks

  const onLoginSuccess = useCallback(() => {
    // If user was intending to go and was redirected to login because
    // user was unauthenticated redirect to that path
    // or redirect to dashboard
    // TODO: To make sure that the state is set before navigating
    setTimeout(() => {
      navigate(location.state?.fromPath ?? '/dashboard');
    }, 50);
  }, [location, navigate]);

  // callbacks

  // If the user has to go through a challenge display that challenge name
  if (authChallenge) {
    switch (authChallenge) {
      case CognitoAuthChallenge.SMS_MFA: {
        return <SMSMFA onLoginSuccess={onLoginSuccess} />;
      }
    }
  }

  // display the login form
  return (
    <UserLoginMainLayout containerClassName="max-w-sm" className="items-center">
      <div className="flex w-full flex-col gap-4">
        <UserLoginHeader heading={LoginTitle} subHeading={LoginDesc} />
        <UserSocialLogin appleLogin={appleLogin} googleLogin={googleLogin} />
      </div>
      <Divider text={orLabel} textClassName="font-normal" className="text-xs text-zinc-400" />
      <UserLoginForm className="pt-5" onLoginSuccess={onLoginSuccess} />
      <UserLoginMainFooter />
    </UserLoginMainLayout>
  );
};

export default UserLoginMain;
