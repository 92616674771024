interface Props {
  speakerData: ListSpeakersResults;
  isSpeakerDataLoading?: boolean;
}
import React, { useEffect, useMemo, useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { DotsThreeVertical, PencilSimple, Translate, UserMinus } from 'phosphor-react';
import DeleteSpeakerModal from './DeleteSpeakerModal';
import { ListSpeakersResults } from '../../../types/GetSpeakerApiResponse';
import Dialog from '../../../../../components/Dialog';
import CreateAndEditNewSpeaker from '../../Modal/CreateAndEditNewSpeaker';
import { useSpeakerStrapiData } from '../../../hooks/useSpeakerStrapiData';
import { useStrapiSharedData } from '../../../../shared/hooks/useStrapiSharedData';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../../../constants/user';
import useGetSpeakerByIdQuery from '../../../hooks/useGetSpeakerByIdQuery';
import { ERROR_CODE } from '../../../constants';

const RowActionsPopUp = ({ speakerData }: Props) => {
  const { tableProps, modalStrapiData } = useSpeakerStrapiData();
  const { editTitle, addNewSpeakerButton, editSpeakerButton, manageLanguagesTitle } =
    modalStrapiData;

  const handleAction = (actionType: 'EDIT' | 'DELETE') => {
    if (actionType === 'EDIT') {
      setIsEditModalOpen(true);
    }
    if (actionType === 'DELETE') {
      setIsDeleteModalOpen(true);
    }
  };
  const toggleDeleteSpeakerModalVisibility = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isManageModalOpen, setIsManageModalOpen] = useState(false);

  const {
    language: { StrapiLanguageOptions },
  } = useStrapiSharedData();

  // Map `label` to `display` for Select component with null check
  const mappedOptions =
    StrapiLanguageOptions?.map((option: { label: string; value: string }) => ({
      display: option.label,
      value: option.value,
    })) || [];

  // Find the next language to English
  const nextLanguage = mappedOptions.find(
    (option: { value: string }) => option.value !== DEFAULT_PREFERRED_LANGUAGE
  );

  const [selectedLanguage, setSelectedLanguage] = useState(nextLanguage ? nextLanguage.value : '');
  const [buttonLabel, setButtonLabel] = useState('');

  const handleLanguageChange = (newLanguage: string) => {
    setSelectedLanguage(newLanguage);
  };

  const speaker = useGetSpeakerByIdQuery({
    speakerId: speakerData.speakerId,
    language: selectedLanguage,
    isEnabled: isManageModalOpen,
  });

  const memoizedSpeakerData = useMemo(() => speaker.data, [speaker.data]);

  useEffect(() => {
    if (memoizedSpeakerData || speaker.errorType) {
      if (!memoizedSpeakerData && speaker.errorType === ERROR_CODE.SPEAKER_NOT_FOUND) {
        setButtonLabel(addNewSpeakerButton);
      } else {
        setButtonLabel(editSpeakerButton);
      }
    }
  }, [memoizedSpeakerData, speaker.errorType, addNewSpeakerButton, editSpeakerButton]);

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <button aria-label="Speaker Actions">
            <DotsThreeVertical color="currentColor" className="text-zinc-100" size={16} />
          </button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            side={'bottom'}
            align={'end'}
            alignOffset={-25}
            hidden={isDeleteModalOpen}
            className="z-20 min-w-[125px] rounded-lg border border-zinc-700 bg-zinc-800 py-2 px-1 text-xs shadow-[0px_25px_50px_-12px_rgba(0,0,0,0.25)]"
          >
            <DropdownMenu.Item
              onClick={() => {
                setIsEditModalOpen(true);
              }}
              className="flex cursor-pointer gap-x-2 px-3 py-1 text-xs font-medium text-zinc-50 outline-none hover:rounded-md hover:bg-zinc-700"
            >
              <span className="flex flex-row items-center gap-2">
                <PencilSimple
                  size={14}
                  className="inline-block text-gray-50"
                  color="currentColor"
                />
                <span className="text-xs font-medium leading-5 text-zinc-50">
                  {tableProps.editAction}
                </span>
              </span>
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onClick={() => {
                setIsManageModalOpen(true);
              }}
              className="flex cursor-pointer gap-x-2 px-3 py-1 text-xs font-medium text-zinc-50 outline-none hover:rounded-md hover:bg-zinc-700"
            >
              <span className="flex flex-row items-center gap-2">
                <Translate size={14} className="inline-block text-gray-50" color="currentColor" />
                <span className="text-xs font-medium leading-5 text-zinc-50">
                  {tableProps.i18nAction}
                </span>
              </span>
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onClick={() => {
                handleAction('DELETE');
              }}
              className="flex cursor-pointer gap-x-2 px-3 py-1 text-xs font-medium text-zinc-50 outline-none hover:rounded-md hover:bg-zinc-700"
            >
              <span className="flex flex-row items-center gap-2">
                <UserMinus size={14} className="inline-block text-gray-50" color="currentColor" />
                <span className="text-xs font-medium leading-5 text-zinc-50">
                  {tableProps.deleteAction}
                </span>
              </span>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
        <Dialog
          open={isEditModalOpen}
          onOpenChange={(open) => setIsEditModalOpen(open)}
          Content={
            <CreateAndEditNewSpeaker
              type="EDIT"
              modalTitle={editTitle}
              formActionButtonLabel={editSpeakerButton}
              speakerDefaultData={speakerData}
            />
          }
          onInteractOutside={() => {
            setIsEditModalOpen(false);
          }}
        ></Dialog>
        <Dialog
          open={isManageModalOpen}
          onOpenChange={(open) => setIsManageModalOpen(open)}
          Content={
            <CreateAndEditNewSpeaker
              type="MANAGE"
              modalTitle={manageLanguagesTitle}
              speakerDefaultData={speaker.errorType ? speakerData : speaker.data}
              formActionButtonLabel={buttonLabel}
              showLanguageDropdown={true}
              mappedOptions={mappedOptions}
              selectedLanguage={selectedLanguage}
              handleLanguageChange={handleLanguageChange}
              errorType={speaker.errorType}
              setErrorType={speaker.setErrorType}
              isSpeakerDataLoading={speaker.isLoading}
            />
          }
          onInteractOutside={() => {
            setIsManageModalOpen(false);
          }}
        ></Dialog>
        <DeleteSpeakerModal
          speakerId={speakerData?.speakerId as number}
          open={isDeleteModalOpen}
          toggleVisibility={toggleDeleteSpeakerModalVisibility}
        />
      </DropdownMenu.Root>
    </div>
  );
};

export default RowActionsPopUp;
